import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HttpClientModule} from '@angular/common/http';
import { AuthGuard } from './guards/auth-guard.service';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'folder/Inbox',
    // redirectTo: 'edit',
    redirectTo: 'login',
    pathMatch: 'full',
    canLoad: [AuthGuard]
  },
  {
    path: 'view/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'v/:id',
    loadChildren: () => import('./folder/folder.module').then(m => m.FolderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'confirmed/:id',
    loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit/:id',
    loadChildren: () => import('./edit/edit.module').then(m => m.EditPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    //@@@ CODJA
    path: 'reset',
    loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    //@@@ CODJA
    path: 'removesubscriber/:id/:id2/:email',
    loadChildren: () => import('./remove-subscribe/remove-subscribe.module').then(m => m.RemoveSubscribePageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'bdo',
    loadChildren: () => import('./bdo/bdo.module').then( m => m.BdoPageModule),
  },
  // {
  //   path: 'color-picker',
  //   loadChildren: () => import('./color-picker/color-picker.module').then(m => m.ColorPickerPageModule)
  // },
  // {
  //   path: 'admin',
  //   loadChildren: () => import('./admin/admin.module').then(m => m.AdminPageModule)
  // },
  // {
  //   path: 'admin/search',
  //   loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule)
  // },
  // {
  //   path: 'search',
  //   loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule)
  // },
  {
    path: 'profiles',
    loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'groups',
    loadChildren: () => import('./groups/groups.module').then(m => m.GroupsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profiles/group/:groupId',
    loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profiles/group/:groupId/:lang',
    loadChildren: () => import('./profiles/profiles.module').then(m => m.ProfilesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profiles/group/:groupId/new',
    loadChildren: () => import('./edit/edit.module').then(m => m.EditPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'import/profiles/:groupId/:groupEntityId',
    loadChildren: () => import('./import-profiles/import-profiles.module').then(m => m.ImportProfileModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'distributors',
    redirectTo: 'login',
    pathMatch: 'full'
   // loadChildren: () => import('./distributors/distributors.module').then(m => m.DistributorsPageModule),
   // canLoad: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reports',
    loadChildren: () => import('./reports/reports.module').then(m => m.ReportsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'info',
    loadChildren: () => import('./info/info.module').then(m => m.InfoPageModule)
  },
  {
    path: 'imports',
    loadChildren: () => import('./imports/imports.module').then(m => m.ImportsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reports-subscription',
    loadChildren: () => import('./reports-subscription/reports-subscription.module').then(m => m.ReportsSubscriptionPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'help',
    loadChildren: () => import('./help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'save-card',
    loadChildren: () => import('./save-card/save-card.module').then( m => m.SaveCardModule)
  },
  {
    path: 'offline-mode',
    loadChildren: () => import('./offline-mode/offline-mode.module').then(m => m.OfflineModeModule)
  },


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
    HttpClientModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
