export const firebaseConfig = {
  apiKey: 'AIzaSyAvM5AK0YptIuZw3vuAaFL3LFPFy749rjk',
  authDomain: 'newqrlead2.firebaseapp.com',
  databaseURL: 'https://newqrlead2.firebaseio.com',
  projectId: 'newqrlead2',
  storageBucket: 'newqrlead2.appspot.com',
  messagingSenderId: '1043342152852',
  appId: '1:1043342152852:web:1d26a3bd58205e44895081',
  measurementId: 'G-VYESFEZMMF',
  restURL: 'https://europe-west3-newqrlead2.cloudfunctions.net'
}

