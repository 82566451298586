import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DatabaseService} from './database.service';
import {SessionService} from './session.service';

@Injectable({
  providedIn: 'root'
})
export class UserService extends DatabaseService {

  constructor(
      public http: HttpClient,
      public sessionservice: SessionService
  ) {
    super(http);
  }

  async addDistributor(distributor: any) {
    return this.createDistributor(distributor);
  }

  async getUsers$(distributor: string, filter: any) {
    return this.getEntities$(distributor, filter, null, 'users', 'user');
  }

  async getUser$(userId: string) {
    return this.get$(userId);
  }

  async sendWelcomeEmail() {
    return this.testMail();
  }

  async registerUser$(distributor: any, user: any) {
    return this.registerUser(distributor, user);
  }

  async storeUserDataBeforeEmailVerification$(distributor: any, user: any) {
    return this.storeUserDataBeforeEmailVerification(distributor, user);
  }

  async createUser$(distributor: any, user: any) {
    return this.save$(distributor, user);
  }

  //@@@codja 9.12.22
  async deleteUser(distributor: any, user: any, parentId: number = 0, row: any = {} ) {
    const currentUser = this.sessionservice.getActiveUser();
    return this.removeUser$(user, distributor, currentUser._id, parentId, row.id );
  }

  getPath() {
    return 'user';
  }

  getLabel() {
    return 'user';
  }

  getId() {
    return super.getId('users');
  }

}
