import { Injectable } from '@angular/core';
import {DatabaseService} from './database.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class DistributorService extends DatabaseService {

  constructor(public http: HttpClient) {
    super(http);
  }

  getDefaultDistributor(){
    return  {
      _id: 'w2p4u',
      name: 'w2p4u',
      logo: '/assets/images/default-logo.png'
    };
  }


  async getDistributors$(filter: any) {
    return this.getEntities$(null, filter, null, 'distributors', 'distributor');
  }


  async getDistributor$(distributorId: string) {
    return this.get$(distributorId);
  }

  async createDistributor$(distributor: any) {
    return this.save$(null, distributor);
  }

  getPath(){
    return 'distributor';
  }

  getLabel() {
    return 'distributor';
  }

  getId() {
    return super.getId('distributors');
  }
}
