export const appConfig = {
  settings: {
    localDB: false,
    localDBUrl: 'http://localhost:8081',
    isDemo: false,
    langs: [
      {lang: 'en', label: 'English', dir: 'ltr', name: 'ENGLISH'},
      {lang: 'he', label: 'Hebrew', dir: 'rtl', name: 'HEBREW'},
      {lang: 'ar', label: 'Arabic', dir: 'rtl', name: 'ARABIC'},
      {lang: 'fr', label: 'French', dir: 'ltr', name: 'FRENCH'},
      {lang: 'ru', label: 'Russian', dir: 'ltr', name: 'RUSSIAN'},
      {lang: 'de', label: 'German', dir: 'ltr', name: 'DEUTSCH'},
      {lang: 'es', label: 'Espanol', dir: 'ltr', name: 'ESPANOL'},
      {lang: 'sw', label: 'Svenska', dir: 'ltr', name: 'SWEDEN'},
      {lang: 'pt', label: 'Portugesae', dir: 'ltr', name: 'PORTUGEZ'},
    ],
    fonts: [
      {label: 'default', value: ''},
      {label: 'clearSans-Light', value: '/assets/fonts/clearSans/ClearSans-Light.ttf'},
      {label: 'clearSans-Regular', value: '/assets/fonts/clearSans/ClearSans-Regular.ttf'},
      {label: 'clearSans-Bold', value: '/assets/fonts/clearSans/ClearSans-Bold.ttf'},
      {label: 'intelone-display-Regular', value: '/assets/fonts/intelone-display/intelone-display-regular.ttf'},
      {label: 'intelone-display-Bold', value: '/assets/fonts/intelone-display/intelone-display-bold.ttf'},
    ],
    default: {
      lang: 'en',
      logo: 'new-default-logo.png',
      background: 'default-background.jpg',
      profile: 'default-profile.png',
      color: '#00a8ec'
    },
    help: {
      url: 'https://www.cmycard.info/#3'
    },
    domain: 'cmycard.online'
  }
};
