import {Injectable} from '@angular/core';
import {appConfig} from './appConfig';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {isEmpty} from '../utils/utils';
import firebase from 'firebase/app';
import 'firebase/storage';
import {firebaseConfig} from './credentials';


@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor(public http: HttpClient) {
  }


  protected getDatabaseUrl() {
    if (appConfig.settings.localDB) {
      return appConfig.settings.localDBUrl;
    } else {
      return firebaseConfig.restURL + '/rest';
    }
  }

  protected async createDistributor(distributor: any) {
    const httpParams = new HttpParams();
    const httpOptions = await this.getHttpOptions(httpParams);
    return this.http.post(`${this.getDatabaseUrl()}/api/distributor`, {distributor}, httpOptions).toPromise().then(newDistributor => {
      return Promise.resolve(newDistributor);
    });
  }


  // async getHttpOptions(params: HttpParams = null) {
  protected async getHttpOptions(params: HttpParams = null, forExport: boolean = false) {

    let token;
    if (firebase.auth() && firebase.auth().currentUser) {
      token = await firebase.auth().currentUser.getIdToken();
    }

    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      headers['Authorization'] = 'Bearer ' + token;
    }
    const httpOptions = {
      headers: new HttpHeaders(headers)
    };
    if (forExport) {
      httpOptions['responseType'] = 'blob';
    }

    const paramsKey = 'params';
    if (!isEmpty(params)) {
      httpOptions[paramsKey] = params;
    }
    return httpOptions;
  }

  protected async get$(entityId: string): Promise<any> {
    return this.getEntity$(entityId, null);
  }

  protected async exportEntities(path: string, filter: any, distributor: string): Promise<any> {
    const body = {type: path, distributor, filter};
    const httpParams = new HttpParams();
    const httpOptions = await this.getHttpOptions(httpParams, true);
    return this.http.post(`${this.getDatabaseUrl()}/api/export`, body, httpOptions).toPromise().then(entity => {
      return Promise.resolve(entity);
    });
  }


  protected async getEntityByUserId(userId: string, lang: string = null, type = null) {
    const httpParams = new HttpParams();
    const httpOptions = await this.getHttpOptions(httpParams);
    let path = `${this.getDatabaseUrl()}/api/entityById/${userId}`;
    if (lang) {
      path = path += `/${lang}`;
    }
    if ( type != null ) {
      path = `${this.getDatabaseUrl()}/api/entityByIds/${userId}/${type}`;
    }
    return this.http.get(path, httpOptions).toPromise().then(entity => {
      return Promise.resolve(entity);
    });
  }

  protected async getEntity$(entityId: any, callback: () => void) {
    const path = this.getPath();
    const label = this.getLabel();

    const httpParams = new HttpParams();
    // httpParams = httpParams.append('entityId', entityId);
    const httpOptions = await this.getHttpOptions(httpParams);
    // return this.http.get(`${this.getDatabaseUrl()}/api/${path}`, httpOptions).toPromise().then(entity => {
    return this.http.get(`${this.getDatabaseUrl()}/api/${path}/${entityId}`, httpOptions).toPromise().then(entity => {
      return Promise.resolve(entity);
    });
  }

  async save$(distributor: any, entity: any): Promise<any> {
    return this.saveEntity$(distributor, entity, null);
  }

  async storeUserDataBeforeEmailVerification(distributor: any, user: any): Promise<any> {
    user['distributor'] = {
      id: distributor._id,
      name: distributor.name,
    };
    const path = 'v1/register-candidate';
    //   const httpOptions = await this.getHttpOptions();
    if (!user['id']) {
      user['id'] = this.getId(path);
    }
    return new Promise((resolve, reject) => {
      this.http.post(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(user), {headers: {'Content-Type': 'application/json'}})
        .subscribe(() => {
          resolve();
        }, (data) => {
          console.error(data);
          reject(data);
        });
    });
  }

  async updateStatus(distributorId: any, entityId: string, active: boolean): Promise<any> {

    const update = {
      id: entityId,
      'active': active
    };
    update['distributor'] = {
      id: distributorId
    };

    const path = `${this.getLabel()}/${entityId}/status`;
    const httpOptions = await this.getHttpOptions();

    return new Promise((resolve, reject) => {
      this.http.put(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(update), httpOptions)
        .subscribe(() => {
          resolve();
        }, (data) => {
          console.error(data);
          reject(data);
        });
    });
  }

  async registerUser(distributorId: any, user: any): Promise<any> {
    user['distributor'] = {
      id: distributorId
    };
    const path = 'v1/register';
    const httpOptions = await this.getHttpOptions();
    if (!user['id']) {
      user['id'] = this.getId(path);
    }
    return new Promise((resolve, reject) => {
      this.http.post(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(user), httpOptions)
        .subscribe((data) => {
          resolve(data);
        }, (data) => {
          console.error(data);
          reject(data);
        });
    });
  }

  async saveEntity$(distributorId: any, entity: any, callback: () => {}): Promise<any> {
    entity['distributor'] = {
      id: distributorId
    };

    let path = this.getPath();
    const label = this.getLabel();

    if (path.endsWith('s') || path.endsWith('x')) {
      path += 'es';
    } else {
      path += 's';
    }

    const httpOptions = await this.getHttpOptions();
    if (!entity['id']) {
      entity['id'] = this.getId(path);
    }

    return new Promise((resolve, reject) => {
      this.http.post(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(entity), httpOptions)
        .subscribe((data) => {
          resolve(data);
        }, (data) => {
          console.error(data);
          reject(data);
        });
    });
  }


  async update$(entity: any): Promise<any> {
    return this.updateEntity$(entity, null);
  }

  async updateEntity$(entity: any, callback: () => {}): Promise<any> {
    const path = this.getPath();
    const label = this.getLabel();

    const httpOptions = await this.getHttpOptions();
    return new Promise(resolve => {
      this.http.put(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(entity), httpOptions)
        .subscribe((data) => {
          console.error(data);
          resolve(data);
        });
    });
  }

  //@@@ codja - 9.12.22
  async removeUser$(user: any, distributor: any, adminId: string, parentId: number, entityId: string): Promise<any> {
    const path = 'user/remove';
    const httpParams = new HttpParams();
    const body = {entityId, _id: user._id, admin_id: adminId, type: user.type, distributor: user.distributor.id, parent_id: parentId};
    const httpOptions = await this.getHttpOptions(httpParams);
    return new Promise(resolve => {
      this.http.post(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(body), httpOptions)
          .subscribe((data) => {
            resolve(data);
          });
    });

  }

  //@@@ codja
  async getDistributorByEmail( email: string ) {
    const httpParams = new HttpParams();
    const httpOptions = await this.getHttpOptions(httpParams);
    const path = `${this.getDatabaseUrl()}/api/distributorbyemail/${email}`;
    return this.http.get(path, httpOptions).toPromise().then(entity => {
      return Promise.resolve(entity);
    });
  }

  async remove$(entityId: any): Promise<any> {
    return this.removeEntity$(entityId, null);
  }

  async removeEntity$(entityId: string, callback: () => {}): Promise<any> {
    const path = this.getPath();
    const label = this.getLabel();

    let httpParams = new HttpParams();
    httpParams = httpParams.append('entityId', entityId);

    const httpOptions = await this.getHttpOptions(httpParams);
    return new Promise(resolve => {
      this.http.delete(`${this.getDatabaseUrl()}/api/${path}`, httpOptions)
        .subscribe((data) => {
          console.error(data);
          resolve(data);
        });
    });
  }


  getId(collectionName: string) {
    const ref = firebase.firestore().collection(collectionName).doc();
    return ref.id;
  }

  protected getPath(): string {
    throw Error('getPath() not implemented in service');
  }

  protected getLabel(): string {
    throw Error('getLabel() not implemented in service');
  }

  upload(file: File,
         handleProgress: (percent: number) => void,
         handleError: (error: string) => void,
         handleComplete: (downloadUrl: string) => void) {
    // create a storage reference
    const storageRef = firebase.storage().ref(file.name);

    // upload file
    const uploadTask = storageRef.put(file);

    uploadTask.on(
      'state_changed',
      function progress(snapshot) {
        const percentage =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        // console.debug('progress:', percentage);
        handleProgress(percentage);
      },

      function error() {
        // alert('error uploading file');
        handleError('error uploading file');
      },

      async function complete() {
        const downloadURL = await storageRef.getDownloadURL();
        handleComplete(downloadURL);
      }
    );
  }

  //@@@ codja
  async deleteFromStorage( userId: string, type: string ) {
    if ( !userId || !type ) {
      return false;
    }
    const body = {userId, type};
    const path = 'profile_storage/remove';
    return new Promise((resolve, reject) => {
      this.http.post(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}})
          .subscribe(() => {
            resolve();
          }, (data) => {
            console.error(data);
            reject(data);
          });
    });
  }

  //@@@ codja
  async importMultipleProfilesToGroup( data: any ) {
    if ( !data.groupUser ) {
      return false;
    }
    const path = 'v1/profiles-import-group';
    const httpOptions = await this.getHttpOptions();
    return this.http.post(`${this.getDatabaseUrl()}/api/${path}`, JSON.stringify(data), httpOptions).toPromise().then(res => {
      if ( res === 'ok' ) {
        return true;
      }
      return false;
    });
  }

  //@@@ codja 7.2.22
  async getEntities$(distributor: string, filter: any, callback: () => void, path: string, label: string) {
    if (!filter) {
      filter = {};
    }
    const marvelId = this.getUserKeyLocal();
    filter['distributor'] = distributor;
    filter['userIdSend'] = marvelId;

    if (!path) {
      path = this.getPath();
    }
    if (!label) {
      label = this.getLabel();
    }
    const httpOptions = await this.getHttpOptions();
    return this.http.post(`${this.getDatabaseUrl()}/api/${path}/list`, JSON.stringify(filter), httpOptions).toPromise().then(res => {
      return res;
    });
  }

  async getEntityLangs$(distributor: string, userId: string, filter: any, callback: () => void, path: string, label: string) {
    if (!filter) {
      filter = {};
    }
    filter['distributor'] = distributor;
    filter['userId'] = userId;

    if (!path) {
      path = this.getPath();
    }
    if (!label) {
      label = this.getLabel();
    }
    const httpOptions = await this.getHttpOptions();
    return this.http.post(`${this.getDatabaseUrl()}/api/${path}/langs`, JSON.stringify(filter), httpOptions).toPromise().then(res => {
      return res;
    });
  }

  protected async createUser(user: any) {
    return this.http.post(`${this.getDatabaseUrl()}/api/register`, user).toPromise().then(entity => {
      return Promise.resolve(entity);
    });
  }

  protected async testMail() {
    return this.http.post(`${this.getDatabaseUrl()}/api/welcomeMail`, {}).toPromise().then(entity => {
      return Promise.resolve(entity);
    });
  }

  protected async syncUsers() {
    return this.http.post(`${this.getDatabaseUrl()}/api/sync`, {});
  }

  //@@@codja
  async requestPasswordByAdmin( row, adminId ) {
    const filter = {};
    filter['id'] = adminId;
    filter['userId'] = row.userId;
    const httpOptions = await this.getHttpOptions();
    return this.http.post(`${this.getDatabaseUrl()}/api/request-password-admin`, JSON.stringify(filter), httpOptions).toPromise().then(res => {
      console.debug(res);
      return res;
    });
  }

  //@@@codja
  async getSettings( filter ) {
    const httpOptions = await this.getHttpOptions();
    const path = `${this.getDatabaseUrl()}/api/v1/main_settings/${filter['userId']}/${filter['id']}`;
    return this.http.get(path, httpOptions).toPromise().then(res => {
      return Promise.resolve(res);
    });
  }

  //@@@codja
  async getEmailBDO( email: string, lang: string ) {
    const filter = {};
    filter['email'] = email;
    filter['lang'] = lang;
    const httpOptions = await this.getHttpOptions();
    return this.http.post(`${this.getDatabaseUrl()}/api/v1/bdo_email`, JSON.stringify(filter), httpOptions).toPromise().then(res => {
      return res;
    });
  }

  //@@@codja
  async getAllPopups() {
    const filter = {};
    const httpOptions = await this.getHttpOptions();
    const path = `${this.getDatabaseUrl()}/api/get/popups`;
    return this.http.get(path, httpOptions).toPromise().then(res => {
      console.debug(res);
      return Promise.resolve(res);
    });
  }

  //@@@codja
  async getPopupInfo( type: string, lang: string ) {
    const filter = {};
    filter['type'] = type;
    filter['lang'] = lang;
    const httpOptions = await this.getHttpOptions();
    const path = `${this.getDatabaseUrl()}/api/get/popup/${filter['type']}/${filter['lang']}`;
    return this.http.get(path, httpOptions).toPromise().then(res => {
      return Promise.resolve(res);
    });
  }

  private getUserKeyLocal() {
    const additional = 'z!2050y';
    const marvell = localStorage.getItem('marvell');
    if ( marvell ) {
      const reverse2 = this.cjReverse(marvell);
      const myArray = reverse2.split(additional);
      const reverse = this.cjReverse(myArray[0]);
      return reverse.substring(0, reverse.length - 4);
    }
    return '';
  }
  private cjReverse(s){
    return s.split('').reverse().join('');
  }
}
