export enum MenuType {
  USERS = 'USERS',
  PROFILES = 'PROFILES',
  GROUPS = 'GROUPS',
  DISTRIBUTORS = 'DISTRIBUTORS',
  REPORT = 'REPORT',
  SETTINGS = 'SETTINGS',
  REPORTSUBSCRIPTION = 'REPORTSUBSCRIPTION',
  LOGIN = 'LOGIN',
  HELP = 'HELP',
  LANG = 'LANG',
  IMPORTS = 'IMPORTS',
  WALKME = 'WALKME',
  SAVBEDIBIZ = 'SAVBEDIBIZ',
  CMYCARDWITHOUTINTERNET = 'CMYCARD WITHOUT INTERNET',
}
