import {Injectable} from '@angular/core';
import {CanLoad, Route, Router, UrlSegment} from '@angular/router';
import {Observable} from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {SessionService} from '../session.service';
import {UserService} from '../user.service';
import {RoleType} from '../enums/role-types';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private router: Router,
              private session: SessionService,
              private userService: UserService) {
  }


  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): boolean | Observable<boolean> | Promise<boolean> {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged(async (authUser) => {
        if (authUser) {
          console.log('User is logged in');
          let user = this.session.getActiveUser();
          if (!user) {
            user = await this.userService.getUser$(authUser.uid);
            if (user) {
              this.session.setActiveUser(user);
            } else {
              this.router.navigateByUrl('/login');
              resolve(false);
            }
          }
          console.debug('route', route);
          if (user.type === RoleType.PROFILE) {
            if (route.path === 'groups' || route.path === 'users' || route.path === 'distributors' || route.path === 'reports' || route.path === 'settings' || route.path === 'reports-subscription') {
              this.router.navigateByUrl('/login');
              resolve(false);
              return;
            }
          }
          else if (user.type === RoleType.GROUP) {
            if (route.path === 'users' || route.path === 'distributors' || route.path === 'settings' ) {
              this.router.navigateByUrl('/login');
              resolve(false);
              return;
            }
          }
          else if (user.type === RoleType.DISTRIBUTOR) {
            if (route.path === 'distributors' || route.path === 'reports' || route.path === 'users' || route.path === 'settings' ) {
              this.router.navigateByUrl('/login');
              resolve(false);
              return;
            }
          }
          resolve(true);
        } else {
          if (location.pathname.includes('/confirmed') || location.pathname.includes('/register') || location.pathname.includes('/v') || location.pathname.includes('/removesubscriber') || location.pathname.includes('/bdo')) {
            resolve(true);
          } else {
            console.log('User is not logged in');
            this.router.navigateByUrl('/login');
            resolve(false);
          }
        }
      });
    });
  }
}
